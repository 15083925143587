<template>
  <questionnaire :title="$t('questionnaires.pest_followup.title')">
    <alert type="info" v-if="positive === true" v-html="$t('questionnaires.pest_followup.introduction.positive')"></alert>
    <alert type="info" v-if="positive === false" v-html="$t('questionnaires.pest_followup.introduction.negative')">
    </alert>
    <row class="g-2">
      <template v-if="positive == true">
        <form-group :error="errors.pest_positive" :di="dataitems.pest_positive" cols="8|4" bp="md" class="mb-4">
          <btn-group outline :di="dataitems.pest_positive" v-model="records.pest_positive" justified></btn-group>
        </form-group>
      </template>
      <template v-else>
        <form-group :error="errors.pest_negative" :di="dataitems.pest_negative" cols="8|4" bp="md" class="mb-4">
          <btn-group outline :di="dataitems.pest_negative" v-model="records.pest_negative" justified></btn-group>
        </form-group>
      </template>
      <form-group :error="errors.rheumatologist" :di="dataitems.rheumatologist" cols="8|4" bp="md" class="mb-4">
        <btn-group outline :di="dataitems.rheumatologist" v-model="records.rheumatologist" justified></btn-group>
      </form-group>
      <form-group :error="errors.psa_diagnosis" :di="dataitems.psa_diagnosis" cols="8|4" bp="md" class="mb-4">
        <btn-group outline :di="dataitems.psa_diagnosis" v-model="records.psa_diagnosis" justified></btn-group>
      </form-group>
      <form-group :error="errors.other_diagnosis" :di="dataitems.other_diagnosis" cols="8|4" bp="md" class="mb-4">
        <btn-group outline :di="dataitems.other_diagnosis" v-model="records.other_diagnosis" justified></btn-group>
      </form-group>
      <!-- add a file upload here -->
      <collapse :show="records.psa_diagnosis == 1">
        <form-group :error="errors.filename" class="mb-4">
          <label for="files" class="form-label">{{ $t('questionnaires.pest_followup.documents.label') }}</label>
          <!-- existing file uploads -->
          <list-group class="mb-2">
            <list-group-item v-for="(file, index) in uploadedFiles" :key="index" class="d-flex align-items-center p-2">
              <a @click="openDocument(file)" class="icon-link">
                <fa icon="file-alt" size="2x" class="me-2" />
              </a>
              <div class="flex-grow-1">{{ file.name }}</div>
              <!-- output size as kb -->
              <small class="mx-1">{{ $t('questionnaires.pest_followup.documents.uploaded', {
                'date': $d(new
                  Date(file.created_at), 'short', 'en-GB')
              }) }}</small>
              <small class="mx-1">{{ $t('questionnaires.pest_followup.documents.size', {
                'size': formatFileSize(file.size)
              }) }}</small>
              <btn theme="danger" @click.native="deleteFile(file)" size="sm" class="mx-1">
                <fa icon="trash" />
                {{ $t('questionnaires.pest_followup.documents.delete') }}
              </btn>
            </list-group-item>
          </list-group>
          <file-upload id="files" :params="fileUploadParams" api-url="/upload/document" @error="fileUploadError"
            @uploaded="fileUploaded" />
        </form-group>
      </collapse>
    </row>
  </questionnaire>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import questionnaire from "~/mixins/questionnaire.js"
import FileUpload from '~/components/FileUpload.vue'
import axios from 'axios';
export default {
  name: 'pest_followup',
  components: {
    FileUpload
  },
  mixins: [questionnaire],
  mounted() {
    if (this.documents) {
      this.uploadedFiles = this.documents
    }
  },
  data() {
    return {
      uploadedFiles: [],
    }
  },
  computed: {
    ...mapState('resources/patient', ['patient_issue']),
    ...mapGetters('resources/questionnaires', ['getQuestionnaireExtraData']),
    documents() {
      return this.getQuestionnaireExtraData(this.name).documents
    },
    isSubmitted() {
      if (this.patient_issue && this.patient_issue.patient_questionnaire) {
        return this.patient_issue.patient_questionnaire.find((pq) => pq.questionnaire_id == this.questionnaire.id).valid == 1
      }
      else {
        return false
      }
    },
    positive() {
      if (this.patient_issue && this.patient_issue.other_fields && this.patient_issue.other_fields.previous_pest) {
        return this.patient_issue.other_fields.previous_pest >= 3
      } else {
        return null;
      }
    },
    diagnosedPSA() {
      return (this.records.psa_diagnosis == 1)

    },
    fileUploadParams() {
      return {
        patient_issue_id: this.patient_issue.id,
        questionnaire_id: this.questionnaire.id,
        patient_id: this.records.patient_id,
      }
    },

  },
  methods: {
    ...mapActions('resources/questionnaires', ['updateQuestionnaireExtraData']),
    fileUploaded(response) {
      console.log(response)
      this.uploadedFiles = response.data.documents
      // this.updateQuestionnaireExtraData({ name: this.name, data: { documents: response.data.documents } })
      // this.$store.dispatch('resources/patient/setError', error)
    },
    fileUploadError(error) {
      console.log(error)
      // this.$store.dispatch('resources/patient/setError', error)
    },
    openDocument(file) {
      var fullUrl = this.$root.config.api_url + '/' + file.path;
      window.open(fullUrl, '_blank');
    },
    formatFileSize(bytes) {
      if (bytes >= 1000000000) {
        bytes = (bytes / 1000000000).toFixed(2) + ' GB';
      } else if (bytes >= 1000000) {
        bytes = (bytes / 1000000).toFixed(2) + ' MB';
      } else if (bytes >= 1000) {
        bytes = (bytes / 1000).toFixed(2) + ' KB';
      } else if (bytes > 1) {
        bytes = bytes + ' bytes';
      } else if (bytes == 1) {
        bytes = bytes + ' byte';
      } else {
        bytes = '';
      }
      return bytes;
    },
    async deleteFile(file) {
      // Implement file deletion logic, update `fileUploads` accordingly
      const index = this.documents.findIndex(f => f.name === file.name);
      if (index !== -1) {
        this.documents.splice(index, 1);
      }
      await axios.delete(this.$root.config.api_url + '/delete/document', {
        params: {
          path: file.path,
        },

      })
        .then(response => {
          console.log(response)
          this.uploadedFiles = response.data.documents
          // this.updateQuestionnaireExtraData({ name: this.name, data: { documents: response.data.documents } })
        })

    },
  },
  watch: {
    'documents': {
      handler: function (val, oldVal) {
        console.log('extraData changed', val)
      },
      deep: true
    },
    'questionnaireData.pest_followup.extraData.documents': {
      handler: function (val, oldVal) {
        console.log('questionnaireData.pest_followup.extraData.documents changed', val)
      },
      deep: true
    },
  }
}

</script>
