<template>
  <questionnaire ref="consent" :title="$t('questionnaires.consent.title')" v-if="questionnaireLoaded && dataitems"
    :body="false">
    <row v-if="isTeam">
      <!-- <checkbox v-model="records.informed_consent">{{dataitems.informed_consent.label}}</checkbox> -->
      <!-- <br> -->
      <!-- <checkbox v-if="records.informed_consent" v-model="records.consented">{{dataitems.consented.label}}</checkbox> -->
      <!-- <form-group :error="errors.informed_consent" :di="dataitems.informed_consent" :sizes="{md:6}">
        <textbox :di="dataitems.informed_consent" v-model="records.informed_consent" />
      </form-group> -->
    </row>
    <row v-if="questionnaireLoaded" class="g-1">
      <column v-if="isConsented" :sizes="{ md: 3 }">
        <card :title="$t('questionnaires.consent.current_consent')" head :body="false" class="m-2">
          <list-group flush>
            <list-group-item v-for="(rec, index) in activeFormRecords" :key="rec.id"
              :class="{ active: isFormRecordSelected(rec) }" @click="setSelectedForm(rec)">
              <h5>{{ rec.section.label }}</h5>
              <i18n path="questionnaires.questionnaire.saved_at" tag="small">
                <time slot="datetime" :val="rec.records.created_at">{{
                  i18nDate(rec.records.created_at)
                }}</time>
              </i18n>
            </list-group-item>
          </list-group>
        </card>
        <!-- <card :title="$t(lp+'.inactive_consent')" head :body="false">
          <list-group flush>
            <list-group-item v-for="rec in inactiveFormRecords" :key="rec.id" :class="{active: isFormRecordSelected(rec)}" @click="setSelectedForm(rec)">
              <h5>{{rec.section.label}}</h5>
              <small>
                Saved: <time :val="rec.records.created_at">{{
                  i18nDate(rec.records.created_at)
                  }}</time>
              </small>
            </list-group-item>
          </list-group>
        </card> -->
      </column>
      <column :sizes="{ md: isFirstConsent ? 12 : 9 }">
        <consent-form v-if="questionnaireLoaded" :mode="mode" :form="selectedSectionDataitem"
          :disabled="selectedRecordDisabled" :dataitems="selectedDataitems" :records="selectedSectionRecords"
          :country="questionnaire.extraData.country" :pis="questionnaire.extraData.pis_info" :errors="selectedSectionErrors">
        </consent-form>
      </column>
    </row>
    <div v-if="savedInvalidConsent">
      <alert type="danger" class="mx-2 mb-4 mx-md-5 px-md-5">
        <i18n path="questionnaires.consent.invalid_consent_warning">
          <template #study_team>
            <a class="fw-bold" :href="'mailto:' + $t('questionnaires.consent.study_team_email')" target="_blank"
              v-t="'questionnaires.consent.study_team_label'" />
          </template>
        </i18n>
      </alert>
      <hr>
      <form-group :di="dataitems.invalid_consent_reason" class="mx-4 mb-4 mx-md-5">
        <textarea class="form-control" :di="dataitems.invalid_consent_reason" v-model="records.invalid_consent_reason" />
      </form-group>
    </div>

    <template #save-btn>
      <btn outline :disabled="questionnaireSaving" @click.native="saveConsent('gotoNextQuestionnaire')">
        <span v-t="'questionnaires.questionnaire.save'"></span>
      </btn>
    </template>
    <template #next-btn>
      <btn outline v-if="completedForm && isFirstConsent" @click.native="updatePatientAndProgress"
        v-t="'questionnaires.questionnaire.next'"></btn>
      <span></span>
    </template>
  </questionnaire>
</template>
<script>
import questionnaire from "~/mixins/questionnaire.js"
import section from "~/mixins/section.js"
import { mapGetters, mapActions } from 'vuex'
import consentForm from '~/pages/questionnaires/consent-form.vue'
import { getNextKeyInSequence } from '~/router/sequences.js'
import { orderBy } from 'lodash-es'
export default {
  mixins: [questionnaire, section],
  components: { consentForm },
  data() {
    return {
      selected: {
        sectionName: null,
        index: null
      },
    }
  },
  mounted() {
    if (this.questionnaireLoaded && this.isFirstConsent) {
      /* Setup empty record */
      var selectedSectionName = this.questionnaire.extraData.activeConsent
      if (this.records[selectedSectionName].length == 0) {
        var count = this.addEmptyRecordToSection(selectedSectionName)
        this.selected.sectionName = selectedSectionName
        this.selected.index = count - 1
      } else {
        this.selected.sectionName = selectedSectionName
        this.selected.index = 0
      }
    }
    if (this.isConsented) {
      this.selected.sectionName = this.activeFormRecords[0].section.name;
      this.selected.index = 0
    }
  },
  computed: {
    ...mapGetters('auth', ['user', 'isTeam']),
    mode() { return this.questionnaire.extraData.consentMode; },
    isFirstConsent() { return this.mode == 'first_consent' },
    isConsented() { return this.mode == 'consented' },
    selectedSectionName() {
      if (this.isFirstConsent) {
        return this.questionnaire.extraData.activeConsent;
      } else if (this.selected.sectionName == null) {
        return this.activeFormRecords[0].section.name;
      } else {
        return this.selected.sectionName;
      }
    },
    selectedSectionDataitem() { return this.dataitems[this.selectedSectionName] },
    selectedDataitems() { return this.dataitems.fromSection(this.selectedSectionName); },
    selectedSectionRecords() { return this.records[this.selectedSectionName][this.selected.index] },
    selectedRecordDisabled() { return false },
    savedInvalidConsent() { if (this.questionnaire) return this.questionnaire.records.valid == 0 && Object.keys(this.errors).length > 0 },
    completedForm() { if (this.questionnaire) return this.questionnaire.records.valid == 1 && Object.keys(this.errors).length == 0 },
    selectedSectionErrors() {
      var errors = {}
      for (var fulldiName in this.errors) {
        var error = this.errors[fulldiName]
        var diName = fulldiName.replace(this.selectedSectionName + '.' + this.selected.index + '.', '')
        errors[diName] = this.errors[fulldiName]
      }
      return errors
    },
    existingForms() { return Object.keys(this.existingFormMap); },
    existingFormMap() {
      var existingSections = {};
      for (var section of this.dataitems.listSections()) {
        // console.log(this.records, section)
        if (this.records[section].length > 0) existingSections[section] = true;
      }
      return existingSections;
    },
    activeFormRecords() {
      var activeRecords = []
      this.existingForms.forEach((formName) => {
        activeRecords.push({ section: this.dataitems[formName], records: this.getDateSorted(this.getSectionByName(formName), "created_at", "desc")[0] })
      })
      return this.getDateSorted(activeRecords, "records.created_at", "desc")
    }

  },
  methods: {
    ...mapActions('auth', ['updateUsersPatient']),
    getDateSorted(arr, field, direction) {
      return orderBy(arr, field, direction)
    },
    setSelectedForm(form) {
      this.selected.sectionName = form.section.name;
      this.selected.index = form.records.id;
    },
    isFormRecordSelected(formRecord) {
      if (formRecord.section.name == this.selectedSectionName) {
        return this.records[this.selectedSectionName].findIndex((r) => r == formRecord.records) == this.selected.index
      }
    },
    saveConsent() {
      this.$refs.consent.saveRecords()
        .catch((err) => {
          // console.log('err', err)
        })
        .then(() => {
          if (this.questionnaire && this.questionnaire.wasValidated && this.questionnaire.records.valid == 1)
            this.updatePatientAndProgress()
        });

      // this.updatePatientAndProgress()
    },
    updatePatientAndProgress() {
      if (this.user.patient) {
        var in_sequence = this.user.patient.in_sequence
        var isConsented = this.$store.getters['auth/isConsented']
        if (this.user.patient.status == 'consent') {

          var status = getNextKeyInSequence(in_sequence, 'consent')
          this.updateUsersPatient({ id: this.user.patient.id, status, in_sequence })
            .then(() => {

              this.$router.push({ name: 'confirmation' });
              this.$gtag.event({
                event_category: 'auth',
                event_action: 'consent',
                value: 'User consented',
                user_id: this.user.id
              })
            })
        }


      }
    }
  },
  watch: {
    'questionnaire.records'() {
    }
  }
}

</script>
