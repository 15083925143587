<template>
    <div>
        <div v-if="request.loading" class="progress my-4" role="progressbar" aria-label="Basic example"
            :aria-valuenow="request.percentageComplete" aria-valuemin="0" aria-valuemax="100">
            <div class="progress-bar" :style="'width:' + request.percentageComplete + '%'"></div>
        </div>
        <row class="gender-row g-0">
            <column v-for="(country, n) in countryData" :key="'country' + country.id">
                <chart class="genderSvg" :width="220" :height="250" :margin="{
                    left: 10,
                    right: 10,
                    bottom: 0,
                    top: 0,
                }">
                    <!-- Show Gender per country -->
                    <pie-component :data="pieData(country.id)" :width="200" :height="200" x="100" y="100"
                        :colors="['var(--bs-primary)', 'var(--bs-pink)']" :transform="'translate(100,100)'" />

                    <use :href="'/images/home/flags-sprite.svg#' + country.code.toLowerCase()" y="206" x="50"
                        preserveAspectRatio="none" width="100" height="66"></use>
                </chart>
            </column>

        </row>

    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import pieComponent from '~/components/charts/pie.vue'
export default {
    name: 'gender-diagram',
    components: {
        pieComponent
    },
    mounted() {
        if (!this.request.loaded && !this.request.loading) this.fetchGenderData()
    },
    beforeDestroy() {
        this.cancelFetch(this.resourceId)
    },
    computed: {
        ...mapState('resources/admin', ['dashboard']),
        resourceId() {
            return 'patient.gender'
        },
        countryData() {
            if (this.dashboard.data.countryData)
                return this.dashboard.data.countryData
            else return []
        },
        patients() {
            if (this.dashboard.stream.patients)
                return this.dashboard.stream.patients
            else return []
        },
        genderTotals() {
            if (this.request)
                return this.patients.reduce((acc, patient) => {
                    acc[patient.g] = acc[patient.g] ? acc[patient.g] + 1 : 1
                    return acc
                }, {})
            else return {}
        },
        genderByCountry() {
            if (this.request)
                return this.patients.reduce((acc, patient) => {
                    acc[patient.c] = acc[patient.c] ? acc[patient.c] : {}
                    if (typeof patient.g !== 'undefined' && patient.g !== null)
                        acc[patient.c][patient.g] = acc[patient.c][patient.g] ? acc[patient.c][patient.g] + 1 : 1
                    return acc
                }, {})
            else return {}
        },
        request() {
            if (this.dashboard.stream.requests[this.resourceId])
                return this.dashboard.stream.requests[this.resourceId]
            else return {
                loading: false,
                loaded: false
            }
        }
    },
    methods: {
        ...mapActions('resources/admin', ['fetchAsStream', 'cancelFetch']),
        fetchGenderData() {
            this.fetchAsStream({
                id: this.resourceId,
                resource: 'Patient',
                key: 'patients',
                initial: false,
                params: {
                    include: ['personal_details'],
                    attrs: ['gender'],
                    chunk: 1000
                }
            })
        },
        pieData(countryId) {
            var gbc = this.genderByCountry[countryId]
            var country = this.countryData.find(c => c.id == countryId)
            if (!gbc || !Object.keys(gbc).length) return [{ label: 'M', value: 49 }, { label: 'F', value: 51 }]
            return Object.keys(gbc || {}).map(g => ({
                label: g,
                value: gbc[g]
            }))

        },
    }
}
</script>
<style>
.genderSvg {
    aspect-ratio: 1/1.34;
    margin: auto;
}

.gender-row {
    container-name: gender-row;
    container-type: inline-size;
    transition: all 1s ease;
}

.pie-slice-label {
    font-size: 1rem;
    fill: white;
    text-anchor: middle;
}

.pie-slice {
    stroke: var(--bs-body-color);
    fill-opacity: 0.35;
    stroke-opacity: 0.7;
}

@container gender-row (min-width: 1200px) {
    .row.gender-row .col {
        flex: 0 0 10%;
    }
}

@container gender-row (max-width: 1200px) {
    .row.gender-row .col {
        flex: 0 0 25%;
    }
}
</style>