export default {
  computed: {

  },
  methods: {
    getSectionByName(sectionName){
      return this.records[sectionName]
    },
    addEmptyRecordToSection(sectionName) {
      var defaults = { ...this.defaultTemplate[sectionName] }
      var length = this.addRecordToSection(sectionName, defaults)
      this.watchNewSection(sectionName, length - 1)
      return length
    },
    addRecordToSection(sectionName, record){
      var length = this.records[sectionName].push(record)
      this.watchNewSection(sectionName, length - 1)
      return length
    }, 
    watchNewSection(sectionName, index) {
      var keys = Object.keys(this.records[sectionName][index])
      keys.forEach((name) => {
        var watcher = this.$watch('records.' + sectionName + '.' + index + '.' + name, function(newVal, oldVal) {
          if(!this.routeChanging&&this.questionnaire.wasValidated) this.validate_dataitem([name], index)
        })
        if(this.$children[0]&&this.$children[0].unwatches) this.$children[0].unwatches.push(watcher)
      })
    },
    deleteSection(sectionName, index) {
      this.records[sectionName].splice(index, 1)
    },
  },

}
