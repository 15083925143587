import { mapActions, mapGetters, mapState } from 'vuex'
import store from '~/store'
import dateMixin from '~/mixins/dates'
import langMixin from '~/mixins/lang'
import questionnaireGettersMixin from '~/mixins/questionnaire_getters'
import validationMixin from '~/mixins/validation'
import visitMixin from '~/mixins/visit'

const questionnaireQueryParams = { append: 'lookups', include: ['dataitems.validationRules'], records: true }
const patientQueryParams = { append: 'code,issues', include: ['user'] }
export default {
  mixins: [dateMixin, langMixin, questionnaireGettersMixin, validationMixin, visitMixin],
  provide() {
    return {
      parent_name: this.name,
      parent_patient_id: this.patient_id,
      parent_issue: this.issue,
    }
  },
  props: {
    name: String,
    patient_id: [String, Number],
    issue: [String, Number],
    nonRoute: Boolean,
  },
  data() {
    return {

    }
  },
  beforeRouteEnter(to, from, next) {
    var name = to.meta && to.meta.name ? to.meta.name : to.name
    var req = {
      name,
      params: { ...to.params, ...questionnaireQueryParams }
    }
    var patientLoaded = store.state['resources/patient'].patient
    if (to.params.patient_id && (Object.keys(patientLoaded).length === 0 || patientLoaded.id != to.params.patient_id)) {
      var params = { patient_id: to.params.patient_id, ...patientQueryParams }
      store.dispatch('resources/patient/getPatientOverview', { params })
    }
    if (to.meta.prefix) req.prefix = to.meta.prefix
    store.dispatch('resources/questionnaires/getQuestionnaireData', req).then((res) => {
      next()
    })
    // store.state['resources/questionnaires'].routeChanging = false
  },
  beforeRouteLeave(to, from, next) {
    if (this.questionnaireRecordsChanged) {
      var childComponent = this.$children[0]
      if (childComponent && childComponent.showSaveChangesDialog) {
        childComponent.showSaveChangesDialog(next)
        return;
      }
    }
    this.update({ routeChanging: true, questionnaireLoaded: false })
    next()
    // console.log('beforeRouteLeave Questionnaire mixin', from.name)

  },
  mounted() {
    if (this.nonRoute) {
      this.getQuestionnaire()
    }
  },
  computed: {
    ...mapState('resources/questionnaires', ['routeChanging', 'questionnaireLoaded', 'questionnaireSaving', 'questionnaireRecordsChanged']),
    isSubmitted() {
      return false;
    }
  },
  methods: {
    ...mapActions('resources/questionnaires', ['getQuestionnaireData', 'update']),
    getQuestionnaire() {
      var params = { patient_id: this.patient_id, issue: this.issue, ...questionnaireQueryParams }
      var req = { name: this.name, params }
      if (this.$route.meta.isPublic) req.prefix = 'public/'
      this.getQuestionnaireData(req)

    },
    clearRecords(records, fields) {
      if (typeof fields == 'object') fields = Object.keys(fields)
      for (var i = fields.length - 1; i >= 0; i--) {
        records[fields[i]] = null
      }
    },
    dataitemLocked(dataitem) {
      return this.questionnaire.extraData.has_previous && this.defaultTemplate[dataitem.name] && dataitem.json.locked;
    },


  },
  watch: {
    'questionnaire.records': {
      handler(val, oldVal) {
        if (oldVal != null && typeof oldVal !== 'undefined') {
          var original = JSON.parse(this.questionnaire.originalRecords)
          var changed = false
          for (var i in val) {
            // make sure string values are compared as strings
            if (typeof val[i] === 'string' && typeof original[i] === 'number' && val[i] != original[i]) {
              if (!isNaN(val[i])) var myVal = Number(val[i])
              if (myVal != original[i]) changed = true
              break;
            }
            else if (typeof val[i] === 'number' && typeof original[i] === 'string' && val[i] != original[i]) {
              if (!isNaN(original[i])) original[i] = Number(original[i])

            }
            else if (typeof val[i] === 'object' && typeof original[i] === 'object') {
              if (JSON.stringify(val[i]) !== JSON.stringify(original[i])) {
                changed = true
                break;
              }
            }
            else if (val[i] != original[i]) {
              changed = true
              break;
            }

          }
          this.update({ questionnaireRecordsChanged: changed })
        }
      },
      deep: true,
      immediate: true

    }
  }
}
