export default {
    computed: {
      lp() {
        if (this.questionnaire)
          return "questionnaires." + this.questionnaire.name;
        if (this.$route.meta && this.$route.meta.name) 
          return"questionnaires." + this.$route.meta.name;
        if (this.$route.params && this.$route.params.questionnaire) 
          return"questionnaires." + this.$route.params.questionnaire;
          return "questionnaires." + this.$route.name;
      },
    },
  
  }
  
