import {factory} from "../utils/ClassLoader"
import helpers from "../utils/helpers"
import * as questionnaireFactory from "./ClassLoader"
import Validator from 'validatorjs'
import Vue from 'vue';
let Record = class extends factory.classes.AttributeBag {
	constructor(values) {
		values = helpers.hardCopy(values)
		super(values)
		// Object.defineProperty(this, "errors", {})

	}
	setQuestionnaire(questionnaire) {
		this.questionnaire = questionnaire
		for(var dataitem of this.dataitems) {
			if (dataitem.section==null&&this[dataitem.name]===undefined) {
				this[dataitem.name] = dataitem.default
			}
		}
		for(var dataitem of this.dataitems) {
			if (this[dataitem.name] && dataitem.isSection()) {
				this[dataitem.name] = questionnaireFactory.factory.classes.SectionRecord.make(this, dataitem);
			}
		}
	}
	// buildDefaults() {
	// 	for(var dataitem of this.dataitems) {
	// 		if (this[dataitem.name] && dataitem.isSection()) {
	// 			questionnaireFactory.factory.classes.SectionRecord.make(this, dataitem);
	// 		}
	// 	}
	// 	var gDataitems = groupBy(this.dataitems, "section")
		
	// 	for (var section in gDataitems) {
	// 		for(var dataitem of gDataitems[section]) {

	// 		}
	// 	}
		
	// }
	#quest;
	#origin;
	errors = {}
	#isValid;
	#wasValidated;
	get wasValidated() {
		return this.#wasValidated;
	}
	set wasValidated(validated) {
		this.#wasValidated = validated;
	}
	get isValid() {
		return this.#isValid;
	}
	set isValid(valid) {
		this.#isValid = valid;
	}
	// get errors() {
	// 	return this.#errors;
	// }
	// set errors(errors) {
	// 	this.#errors = errors;
	// }
	set original(original) {		
		this.#origin = original
	}
	get original() {
		return this.#origin
	}
	set questionnaire(questionnaire) {
		this.#quest = questionnaire
	}
	get questionnaire() {
		return this.#quest
	}
	get dataitems() {
		return this.questionnaire.dataitems
	}
	get sectionDataitems() {
		return this.dataitems.fromSection();
	}
	get exists() {
		return !!this.id;
	}
	get doNotIter() {
		return ["questionnaire"]
	}
	get doNotJson() {
		return ["questionnaire"]
	}
	copy() {
		var copy = new this.__proto__.constructor(helpers.hardCopy(this))
		copy.questionnaire = this.questionnaire
		copy.original = this.original
		return copy;
	}
	revert() {
		var values = helpers.hardCopy(this.original)
		this.assignContents(values)
	}
	toJSON(key) {
		var copy = {};
		for(var index in this) {
			if (this.doNotJson.indexOf(index)===-1) {
				copy[index] = this[index];
			}
		}
		return copy;
    }
	download() {
		var file = "";
		for(var index in this.dataitems) {
			file += index+","
		}
		file += "\n";
		for(var index in this.dataitems) {
			file += this[index]+","
		}
		helpers.download(this.questionnaire.name +" record.csv", file)
	}
	get validationRules() {
		return this.questionnaire.validationRules;
	}
	static make(values, questionnaire) {

		var questionnaireModelName = questionnaireFactory.factory.classes[questionnaire.model_name + "Record"];
		var questionnaireModelType = questionnaireFactory.factory.classes[questionnaire.model_type + "Record"];
		if (questionnaireModelName) {
			var className = questionnaireModelName;
			// var record = new questionnaireModelName(values);
		} else if (questionnaireModelType) {
			var className = questionnaireModelType;
			// var record = new questionnaireModelType(values);
		} else {
			var className = questionnaireFactory.factory.classes.Record;
			// var record = new questionnaireFactory.factory.classes.Record(values);
		}
		var record = new className(values);
		var original = new className(values);
		record.setQuestionnaire(questionnaire);
		original.setQuestionnaire(questionnaire);
		record.original = original;
		return record;
	}
	mergeErrorsIn(errors) {
		for(var index in this.errors) {
			if (!errors[index]) {
				Vue.delete(this.errors, index)
			}
		}
		for(var index in errors) {
			Vue.set(this.errors,index, errors[index])
		}
	}
	validate(options) {
		if (!options) options = {};
		var validationRules = this.questionnaire.validationRules;
		if (options.rule_filter) {
			validationRules = validationRules.filter(options.rule_filter);
		}
		var rules = validationRules.getRules(options)
		var messages = validationRules.getMessages(options)

		var validator = new Validator(this, rules, messages);
		
		if(validator.fails()) {
			if (!options.dont_set_errors) {
				this.mergeErrorsIn(validator.errors.errors);
				this.isValid = false;
			}
			return validator.errors.errors;
		} else {
	    	if (!options.dont_set_errors) {
			  	this.mergeErrorsIn({});
			  	this.isValid = true;
			}
		    return {};	  
		}
	}
	validationWatch(vue, recordLocation, options) {
		if(!options) options = {}
		var rWatcher = vue.$watch(recordLocation, function () {
			vue[recordLocation].validate(options);
		});
		var validateWatchers = [rWatcher];
		for(var dataitem of this.sectionDataitems) {
			var watcher = this.validationWatchDataitem(vue, dataitem, recordLocation, options)
			validateWatchers.push(watcher);
		}
		return function() {
			for (var watcher of validateWatchers) {
				watcher();
			}
		} 
	}
	validationWatchDataitem(vue, dataitem, recordLocation, options) {
		if (!options) options = {}
		return vue.$watch(recordLocation+"."+dataitem.name, function(newVal, oldVal) {
			vue[recordLocation].validate(options);
		});
	}
}

export {
	Record
}