<template>
  <questionnaire :save-behaviour="saveFunc" :title="$t('questionnaires.pest.title')">
    <template v-for="diName in pestItems">
      <form-group :error="errors[diName]" :di="dataitems[diName]" cols="8|4" bp="md" class="mb-4" label-class="fs-5">
        <btn-group outline :readonly="isSubmitted" :di="dataitems[diName]" v-model="records[diName]"
          justified></btn-group>
      </form-group>
    </template>
    <h5 v-t="'questionnaires.pest.figure_instructions'"></h5>
    <svg-map-basic class="body-map my-4 mx-n4" v-if="PSMan" :dataitems="dataitems" :map-data="PSMan" :records="records"
      @input="(r) => records[r.name] = r.value">
    </svg-map-basic>
    <card class="h-100" v-if="allScored && isSubmitted" id="psa-msg">
      <row>
        <column :sizes="{ sm: 6, lg: 4 }" :offset="{ sm: 3, lg: 0 }">
          <h4 class="display-4 text-center text-uppercase" v-t="'questionnaires.questionnaire.score'"></h4>
          <score-speedo :score="{ score, min: 0, max: 5 }" :domain="[0, 2, 4]"
            :colours="['#198754', '#ffc107', '#dc3545']" :ticks="5"></score-speedo>
        </column>
        <column :sizes="{ lg: 8 }">
          <alert type="info">
            <div v-if="allScored && score <= 2">
              <h4 v-t="'questionnaires.pest.feedback.score_lte_2.title'"></h4>
              <p v-t="'questionnaires.pest.feedback.score_lte_2.message'"></p>
            </div>
            <div v-if="allScored && score >= 3">
              <h4 v-t="'questionnaires.pest.feedback.score_3plus.title'"></h4>
              <p v-t="'questionnaires.pest.feedback.score_3plus.message'"></p>
            </div>
          </alert>
        </column>
      </row>
    </card>
  </questionnaire>
</template>
<script>
import { mapState } from "vuex"
import questionnaire from "~/mixins/questionnaire.js"
import { isEmpty } from 'lodash-es'
import scrollMixin from '~/mixins/scroll'
export default {
  name: 'pest',
  mixins: [questionnaire, scrollMixin],
  computed: {
    ...mapState('resources/patient', ['patient_issue']),
    PSMan() {
      return this.questionnaire.extraData.PSMan
    },
    pestItems() {
      return ['pest_1', 'pest_2', 'pest_3', 'pest_4', 'pest_5']
    },
    score() {
      var score = 0
      this.pestItems.forEach((diName) => {
        if (this.records[diName] && this.records[diName] !== 2 && this.records[diName] !== "2") score = score + this.dataitems[diName].json.score
      })
      return score
    },
    allScored() {
      var scored = true
      this.pestItems.forEach((diName) => {
        if (!this.records[diName]) scored = false
      })
      return scored
    },
    isSubmitted() {
      if (this.patient_issue && this.patient_issue.patient_questionnaire) {
        return this.patient_issue.patient_questionnaire.find((pq) => pq.questionnaire_id == this.questionnaire.id).valid == 1

      }
      else {
        return false
      }
    }
  },
  methods: {
    saveFunc(qComp, request) {
      if (isEmpty(this.errors)) {
        setTimeout(function () { this.scrollToElement("footer") }.bind(this), 200)
      }
    },
  },
}

</script>
<style lang="scss">
.body-map {

  svg {

    line,
    path,
    circle,
    ellipse,
    rect {
      stroke: black;
      fill: var(--bs-gray-500);
      // stroke-width: 3px;
      transition: all 0.2s ease;
    }

    .head.ls0 {
      fill: #ecd9d9;
    }

    text {
      stroke: black;
      fill: black;
      font-size: 28px;
      opacity: 0.8;
      transition: all 0.2s ease;
    }

    text.on {
      font-size: 30px;
      font-weight: bold;
      opacity: 1;
    }

    .ls0 {
      fill: white;
      stroke-width: 2;
      stroke-miterlimit: 10;
    }

    .ls1 {
      fill: none;
      stroke-miterlimit: 10;
    }

    .ls2 {
      fill: none;
      stroke-width: 6;
      stroke-miterlimit: 10;
    }

    #Checkboxes {
      rect.on {
        fill: var(--bs-primary);
      }

      rect.hover:not(.on),
      rect:focus:not(.on) {
        stroke: rgba(var(--bs-primary-rgb), 0.2);
        fill: rgba(var(--bs-primary-rgb), 0.5);
      }


    }
  }
}

body.dark-theme {
  .body-map {
    svg {
      stroke: white;

      line,
      path,
      circle,
      ellipse {
        stroke: white;
      }

      #Head {

        line,
        path,
        circle,
        ellipse {
          stroke: black;
        }
      }


      text {
        stroke: var(--bs-gray-500);
        fill: white;
      }

    }
  }
}
</style>
